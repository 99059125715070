import React, { useState } from 'react'
import { useNav } from '../components/customHooks/useNav'
import '../assets/styles/ContactsStyles.css';
import emailjs from 'emailjs-com';
import AlertComponent from '../components/alerts/AlertComponent';
// import SuccessIcon from '../assets/successIcon.svg';
import ErrorIcon from '../assets/errorIcon.svg';




const Contact = () => {

    const contactRef = useNav('Contacts')

    const [state, setState] = useState({
        fullName: '', message: '', email: '', showSuccess: false, showError: false, showWarning: false, error: '', info: '',
    })

    const handleChange = (e) => {
        setState(values => ({
            ...values,
            [e.target.name]: e.target.value
        }))
    }

    const renderSuccess = () => {
        return (
            <AlertComponent Icon={ErrorIcon} message={'Successfully Sent'} background={'rgba(111, 207, 151, 1)'} />
        )
    }

    const renderError = () => {
        return (
            <AlertComponent Icon={ErrorIcon} message={state.error} background={'rgba(218, 96, 96, 1)'} />
        )
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.send('service_4wwihjx', 'template_2avb2gf', state, 'XI0VRbTYnXnVzjkSV').then(response => {
            console.log('SUCCESS', response);
            if (response) {
                setState({ showSuccess: true, fullName: '', email: '', message: '' })
                setTimeout(() => {
                    setState({ showSuccess: false })
                }, 3000)
            }
        }, error => {
            console.log('FAILED....', error);
            if (error) {
                setState({ showError: true, error: error })
                setTimeout(() => {
                    setState({ showError: false, error: '' })
                }, 3000)
            }
        })



    }

    return (
        <>
            <section className='contactsContainer' id='contactsContainer' ref={contactRef}>
                {state.showSuccess && renderSuccess()}
                {state.showError && renderError()}
                {state.showWarning && renderInfo()}

                <div className='contactHeader'>
                    <h2>
                        Contact us today to discuss your business needs.
                    </h2>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className='inputContainer'>
                        <input type={'text'} value={state.fullName} name={'fullName'} onChange={handleChange} placeholder='Full Name' />
                    </div>
                    <div className='inputContainer'>
                        <input type={'email'} value={state.email} name={'email'} onChange={handleChange} placeholder='Email Address' />
                    </div>
                    <div className='inputContainer'>
                        <textarea name='message' value={state.message} onChange={handleChange} placeholder='Message'></textarea>
                    </div>

                    <div className='sendButton'>
                        <button>Send</button>
                    </div>

                </form>
            </section>
        </>
    )
}

export default Contact