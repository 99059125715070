import React from 'react';
import Chisco from '../assets/chisco.svg';
import Fusion from '../assets/wild.svg';
import Solutions from '../assets/solutions.svg';
import E from '../assets/le.svg';
import Beta from '../assets/beta.svg';
import Mercy from '../assets/mercy.svg';
import Chams from '../assets/chams.svg';
import Leaf from '../assets/leaf.svg';
import CJID from '../assets/cjid.svg';


const Clients = () => {
    return (
        <section className={'clientsContainer'}>
            <header>
                <h3>Some of our Clients</h3>
            </header>
            <div className={'clientsGrid'}>
                <img src={Chisco} alt='client' />
                <img src={Fusion} alt='client' />
                <img src={Solutions} alt='client' />
                <img src={E} alt='client' />
                <img src={Beta} alt='client' />
                <img src={Mercy} alt='client' />
                <img src={Chams} alt='client' />
                <img src={Leaf} alt='client' />
                <img src={CJID} alt='client' />


            </div>
        </section>
    )
}

export default Clients