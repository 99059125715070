import React from 'react';
import AboutHero from '../assets/hero.png';
import '../assets/styles/AboutStyles.css';
import { useNav } from '../components/customHooks/useNav';

const Hero = () => {

    const heroRef = useNav('Home')
    return (
        <>

            <section className='heroContainer' ref={heroRef} id='heroContainer'>
                <article className='heroContainerText'>
                    <h2>Strategy <span>with execution</span></h2>
                    <p>
                        At Chronicle Strategy Partners, we believe that success is not just about designing a great strategy,
                        but also about implementing it effectively. That's why we work closely with small businesses to not only develop tailored business models,
                        but also to hand-hold them through the implementation process. Unlike big firms,
                        we don't just drop a report and leave you to figure it out on your own.
                        We're committed to walking alongside you every step of the way, providing the support and guidance you need to achieve your goals.
                        Our team of experienced consultants will help you identify areas of improvement, develop actionable plans,
                        and implement them in a way that works for your unique business. Let us help you take your business to the next level - contact us
                        today to learn more!
                    </p>
                </article>
                <div className='heroImage'>
                    <div className={'heroImageGradient'}>
                        <img src={AboutHero} alt='poster' />
                    </div>
                </div>
            </section>
        </>
    )
}

export default Hero