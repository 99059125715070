import React from 'react';
import './Alerts.css';

const AlertComponent = ({ message, background, Icon }) => {
    return (
        <div className='alertContainer' style={{ background: background }}>
            <img src={Icon} alt='icon' style={{ width: '24px', height: '24px' }} />
            <p>{message}</p>
        </div>
    )
}

export default AlertComponent