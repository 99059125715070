import React from 'react'
import About from './About'
import Contact from './Contact'
import Services from './Services'
import Hero from './HeroSection'
import Everyone from './Everyone'
import Clients from './Clients'

const Main = () => {
    return (
        <main >
            <Hero />
            <About />
            <Services />
            <Everyone />
            <Clients />
            <Contact />
        </main>
    )
}

export default Main