import React, { createContext, useState } from 'react';

export const NavContext = createContext();

const NavProvider = ({ children }) => {

    // const { children } = props;
    const [activeNavLinkId, setActiveNavLinkId] = useState('');

    const providerValue = {
        activeNavLinkId,
        setActiveNavLinkId,
    };

    return (
        <NavContext.Provider value={providerValue}>{children}</NavContext.Provider>
    );
};

export default NavProvider;