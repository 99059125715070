import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/styles/HeroStyles.css';
import './assets/styles/AboutStyles.css';
import './assets/styles/ContactsStyles.css';
import './assets/styles/ServicesStyles.css';
import './assets/styles/EveryoneStyles.css';
import './assets/styles/ClientStyles.css';



ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
