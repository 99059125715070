import React from 'react';
import '../assets/styles/AboutStyles.css';
import { useNav } from '../components/customHooks/useNav';

const About = () => {

    return (
        <>

            <section className='aboutContainer' >
                <article className='aboutContainerText'>
                    <h2>Supporting your business every step of the way</h2>
                    <p>
                        Chronicle Strategy Partners (CSP) is a trusted advisory firm that provides a comprehensive
                        range of consulting services and implementation support to businesses. We are committed to helping our clients
                        achieve their goals by developing and implementing effective strategies that drive real results. We believe that
                        a well-articulated strategy is the foundation of success, but we also know that it's the execution that truly makes the difference.
                        That's why we don't just stop at providing recommendations - we partner with our clients to ensure that their strategies are implemented effectively,
                        and that they see tangible outcomes.
                        We have worked with small and medium sized businesses, governments and technology startups.
                    </p>
                </article>
            </section>
        </>
    )
}

export default About