import NavProvider from "./components/context/NavContext";
import Nav from "./components/nav/Nav";
import Main from "./pages/Main";
import './App.css';
import Footer from "./components/footer/Footer";
// import * as emailjs from "emailjs-com";


function App() {
  return (
    <>
      <div className="responsiveContainer">
        <NavProvider>
          <Nav />
          <Main />
          <Footer />
        </NavProvider>

      </div>
    </>
  );
}

export default App;
