import React from 'react'

const ServicesCard = ({ image, title, text }) => {

    return (
        <div className='serviceCardContainer'>
            <div className='serviceCardImgContainer'>
                <img src={image} alt='logo' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='serviceCardTitleContainer'>
                <h4>{title}</h4>
            </div>
            <div className='serviceCardTextContainer'>
                <p>{text}</p>
            </div>
        </div>

    )
}

export default ServicesCard