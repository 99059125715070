import React from 'react';
import ServicesCard from '../components/cards/ServicesCard';
import { useNav } from '../components/customHooks/useNav';
import '../assets/styles/ServicesStyles.css';
import HandShake from '../assets/servicehand.svg';
import Arrow from '../assets/arrowup.svg';
import Artifacts from '../assets/artifacts.svg';
import Advisory from '../assets/advisory.svg';
import Formulation from '../assets/formulation.svg';
import Modelling from '../assets/modelling.svg';
import Support from '../assets/support.svg';
import Expansion from '../assets/expansion.svg';


const Services = () => {

    const servicesRef = useNav('Services')
    return (
        <>
            <section>
                <section className='servicesContainer' ref={servicesRef} id='servicesContainer'>
                    <div className='servicesHeader'>
                        <h2>What we do?</h2>
                    </div>
                    <div className='servicesGrid'>
                        <ServicesCard
                            image={Formulation}
                            title={'Strategy Development'}
                            text={`We work with clients to develop comprehensive, data-driven strategies that align with their goals and objectives. Our team will help identify areas of opportunity, assess market trends, and create actionable plans to drive growth and success. We are your scribe, helping you map out a clear and compelling vision for your organization's future.`} />
                        <ServicesCard
                            image={Advisory}
                            title={'Implementation Support'}
                            text={`Once a strategy is in place, we provide the necessary support to help clients implement it effectively. This includes project management, human resource support, financial control and business advisory services, all designed to ensure that clients can execute their strategies with confidence. We are your execution partner, helping you turn your vision from paper to reality.`} />
                        <ServicesCard
                            image={Modelling}
                            title={'Organizational Optimization'}
                            text={`We help clients assess and improve their organizational structure, processes, and systems to ensure they are aligned with their strategic goals. This includes services such as organizational design, process improvement, and performance measurement. Our team will resume daily in your office and embed ourselves and then improve the system inside-out. We are your architect, helping you build a strong and efficient foundation for your organization.`} />
                        <ServicesCard
                            image={Expansion}
                            title={'Change Management'}
                            text={`We support clients through times of change, whether it's a merger, acquisition, restructuring, or other significant event. Our team will help develop and implement a comprehensive change management plan that minimizes disruption, maximizes employee engagement, and ensures a successful transition. We are your navigator, helping you chart a smooth course through the waters of change.`} />
                        <ServicesCard
                            image={Support}
                            title={'Growth and Innovation'}
                            text={`We help clients identify and pursue new opportunities for growth and innovation, whether it's through the development of new products or services, expansion into new markets, or digital transformation. Our services include market research and analysis, pitch decks and business plans, financial modelling, product development, and innovation strategy. We are your growth partner, helping you identify and capitalize on new opportunities to drive growth and success.`} />
                        <ServicesCard
                            image={Artifacts}
                            title={'Capacity Development'}
                            text={`We provide customized capacity development programs designed to help clients build the skills and capabilities they need to lead their organizations effectively. Our programs include executive coaching, team building, and leadership training, year end strategy review sessions, all tailored to meet the unique needs of each client. We have trained employees working at global organizations and our sessions have a direct line of sight to revenues and employee output. We are your leadership coach, helping you unlock your full potential as a leader and inspire your team to achieve greatness.`} />
                    </div>
                </section>
                <section className='listSection'>
                    <article>
                        <h5>What Sets Us Apart?</h5>
                        <p>“Impactful and contextual work designed to suit the peculiarities of our clients and help them achieve desired results.”</p>
                        <img src={Arrow} alt='arrow' />
                    </article>
                    <div className={'handImageContainer'}>
                        <img src={HandShake} alt='hand' />
                    </div>
                </section>
            </section>


        </>
    )
}

export default Services