import React from 'react';
import Icon from '../../assets/icon.svg';

const EveryoneCard = ({ title, description, className }) => {
    const cardClasses = `everyoneCardContainer ${className || ''}`;
    return (
        <div className={cardClasses} >
            <div>
                <img src={Icon} alt='icon' />
            </div>
            <article>
                <header>
                    <h4>{title}</h4>
                    <p>{description}</p>
                </header>
            </article>
        </div>
    )
}

export default EveryoneCard