import React from 'react';
import LogoSvg from '../../assets/logo.svg';
import NavLink from './NavLink';
import navLinks from './navLinks';
import './Nav.css';


const Nav = () => {

    const handleClick = () => {
        document.getElementById('contactsContainer').scrollIntoView({ behavior: 'smooth' });

    };


    return (
        <nav className='navContainer'>
            <section className='logoContainer'>
                <img src={LogoSvg} alt='logo' style={{ height: '100%', width: '100%' }} />
            </section>
            <section className='navLinksContainer'>
                {navLinks.map(({ navLinkId, scrollToId }, idx) => (
                    <NavLink key={idx} navLinkId={navLinkId} scrollToId={scrollToId} />
                ))}
            </section>
            <section className={'navContactBtn'}>
                <button onClick={handleClick}>
                    Contact
                </button>
            </section>

        </nav>
    );
};

export default Nav;
