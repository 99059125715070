import React from 'react';
import './Footer.css';
import { FaLinkedinIn, FaInstagram } from "react-icons/fa";


// Linkedin - https://www.linkedin.com/company/chroniclesp
// Instagram - instagram.com / chroniclestrategy
const Footer = () => {
    return (
        <>
            <section className='footerContainer'>
                <div className='companyName'>
                    <p>© 2024 Chronicle Strategy Partners. All Rights Reserved.</p>
                </div>
                <div className='socialLinksContainer'>
                    <ul>
                        <li>
                            <a href='https://instagram.com/chroniclestrategy'>
                                <FaInstagram />
                            </a>
                        </li>
                        <li>
                            <a href='https://www.linkedin.com/company/chroniclesp'>
                                <FaLinkedinIn />
                            </a>
                        </li>
                    </ul>
                </div>
            </section>
        </>
    )
}

export default Footer