import { useContext, useEffect, useRef } from 'react';
import { NavContext } from '../context/NavContext';
import { useOnScreen } from './useOnScreen';


export const useNav = (navLinkId) => {

    // const { navLinkId } = props

    const ref = useRef(null);
    const { setActiveNavLinkId } = useContext(NavContext);

    const isOnScreen = useOnScreen(ref)
    useEffect(() => {
        if (isOnScreen) {
            setActiveNavLinkId(navLinkId)
        }
    }, [isOnScreen, setActiveNavLinkId, navLinkId])
    return ref;

}