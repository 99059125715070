import React, { useContext } from 'react';
import { NavContext } from '../context/NavContext';
import './Nav.css';

const NavLink = ({ navLinkId, scrollToId }) => {

    const { activeNavLinkId, setActiveNavLinkId } = useContext(NavContext);

    const handleClick = () => {
        setActiveNavLinkId(navLinkId);
        document.getElementById(scrollToId).scrollIntoView({ behavior: 'smooth' });

    };

    return (
        <span
            id={navLinkId}
            className={[activeNavLinkId === navLinkId ? 'activeClass' : 'normalClass',]}
            onClick={handleClick}
        >
            {navLinkId}
        </span>
    )
};

export default NavLink;