import React from 'react';
import HandShake from '../assets/everyone.svg';
import EveryoneCard from '../components/cards/EveryoneCard';
import { useNav } from '../components/customHooks/useNav';

const Everyone = () => {
    const aboutRef = useNav('About')
    return (
        <section className={'everyoneContainer'} ref={aboutRef} id='aboutContainer'>
            <header>
                <h3>Everyone needs us</h3>
            </header>
            <div className={'everyoneGrid'}>
                <aside>
                    <img src={HandShake} alt='handshake' />
                </aside>
                <aside>
                    <EveryoneCard className={'ml70'} title={'Small and Medium-sized Businesses'} description={'They love us for our ability to consistently take their ideas from paper, refine them and turn them into profitable business models without breaking the bank. We also love them because they usually do not have the bureaucracies that can slow down implementation and dynamism. Most times, they end up retaining us as a trust business advisor to chaperon them through their journey. We are neither sector nor industry agnostic.'} />
                    <EveryoneCard className={'ml150'} title={'Governments and Public Institutions'} description={`They like us because we understand the complexities of government and use our expertise to craft sustainable models that align with political realities. We remain agnostic to politics, focusing on delivering results that benefit the public. Our partnership creates large-scale impact, reaching a wide range of people and making a positive difference in their lives.`} />
                    <EveryoneCard className={'ml100'} title={'Technology Startups'} description={'Our startups love us because we understand technology and leverage our technical and operational experience to craft winning models. Our deep understanding of technology and proven strategy combine to create disruptive innovation. We focus on long-term value, not short-term buzz.'} />

                </aside>
            </div>
        </section>
    )
}

export default Everyone